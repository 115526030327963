import { gql } from '@apollo/client';
import { CURRENT_SUBSCRIPTION_PLAN_FRAGMENT, USER_FRAGMENT } from './Mutations';

export const SUBSCRIPTION_PLAN_FRAGMENT = gql`
  fragment subscriptionPlanFragment on SubscriptionPlan {
    allowCustomAmount
    amount {
      usd {
        maxPrice
        price
      }
    }
    description
    frequency
    html
    id
    key
    name
    order
    badges {
      id
      image {
        id
        blurhash
        url
      }
      key
      name
    }
    images {
      id
      blurhash
      url
    }
  }
`;

const PAGE_MODULE_DATA_FRAGMENT = gql`
  ${SUBSCRIPTION_PLAN_FRAGMENT}
  fragment AssetFragment on Asset {
    categoryKey
    url
    blurhash
    title
  }

  fragment TopicFragment on Topic {
    id
    key
    name
    slug
    primaryColor
  }

  fragment TagFragment on Tag {
    key
    name
    slug
  }

  fragment VideoFragment on Video {
    id
    title
    description
    slug
    topics {
      id
      name
      slug
      primaryColor
    }
    collections {
      id
      slug
      title
    }
    tags {
      key
      name
      slug
    }
    status
    createdAt
    video: videoAsset {
      id
      url
      information {
        durationInSeconds
      }
    }
    videoThumbnail {
      url
    }
    imageThumbnail {
      url
      blurhash
    }
    image {
      url
      blurhash
    }
    textTracks {
      url
    }
    resources {
      id
      type
      content
    }
    isPremium
    requiredPlan
    previewVideo {
      id
      url
      title
      blurhash
    }
  }
  fragment PodcastFragment on Podcast {
    id
    title
    description
    slug
    topics {
      id
      name
      slug
      primaryColor
    }
    collections {
      id
      slug
      title
    }
    tags {
      key
      name
      slug
    }
    createdAt
    audio {
      id
      url
      information {
        durationInSeconds
      }
    }
    video {
      id
      title
      url
    }
    previewAudio {
      id
      url
      information {
        durationInSeconds
      }
    }
    previewVideo {
      id
      title
      url
    }
    imageThumbnail {
      url
      blurhash
    }
    image {
      url
      blurhash
    }
    requiredPlan
    allowedPlans {
      key
    }
  }

  fragment CollectionFragment on Collection {
    id
    title
    description
    slug
    primaryColor
    itemsCount
    thumbnail {
      url
      blurhash
    }
  }

  fragment CollectionDetailsFragment on Collection {
    id
    title
    description
    slug
    thumbnail {
      url
      blurhash
    }
  }

  fragment VideoContinueWatchingCarouselFragment on VideoContinueWatchingCarouselData {
    settings {
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoCarouselFragment on VideoCarouselData {
    settings {
      viewAll
      videoProgressBar
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Topic {
          topicId: id
        }
        ... on Tag {
          tagId: id
        }
        ... on Collection {
          collectionId: id
        }
      }
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment PodcastListFragment on PodcastListData {
    settings {
      podcastProgressBar
      separator
      viewAll
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Topic {
          topicId: id
        }
        ... on Tag {
          tagId: id
        }
        ... on Collection {
          collectionId: id
        }
      }
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment VideoListFragment on VideoListData {
    settings {
      videoProgressBar
      separator
      viewAll
    }
    config {
      autoGenerate
      autoGenerateByType
      autoGenerateByData {
        ... on Topic {
          topicId: id
        }
        ... on Tag {
          tagId: id
        }
        ... on Collection {
          collectionId: id
        }
      }
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoGridFragment on VideoGridData {
    settings {
      videoProgressBar
      viewAll
      actionButton
    }
    config {
      autoGenerate
      autoGenerateByType
      actionTitle
      autoGenerateByData {
        ... on Topic {
          topicId: id
        }
        ... on Tag {
          tagId: id
        }
        ... on Collection {
          collectionId: id
        }
      }
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoSliderFragment on FeaturedVideoSliderData {
    settings {
      carouselIndicator
      videoCTA
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoFragment on FeaturedVideoData {
    settings {
      videoCTA
    }
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerFragment on VideoPlayerData {
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment TopicCarouselFragment on TopicCarouselData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicListFragment on TopicListData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicGridFragment on TopicGridData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment FeaturedTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    config {
      topic {
        ...TopicFragment
      }
    }
  }

  fragment CollectionPillCarouselFragment on CollectionPillCarouselData {
    settings {
      viewAll
    }
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionCarouselFragment on CollectionCarouselData {
    settings {
      viewAll
      numberOfItems
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionGridFragment on CollectionGridData {
    settings {
      viewAll
      numberOfItems
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionListFragment on CollectionListData {
    settings {
      separator
      viewAll
      numberOfItems
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionSliderFragment on FeaturedCollectionSliderData {
    settings {
      collectionImage
    }
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionFragment on FeaturedCollectionData {
    settings {
      numberOfItems
    }
    config {
      collection {
        ...CollectionFragment
      }
    }
  }

  fragment CollectionDetailFragment on CollectionDetailData {
    settings {
      title
      showProgressBar
      showImageThumbnail
      description
    }
    config {
      collection {
        ...CollectionDetailsFragment
      }
      collectionItems {
        id
        type
        order
        sequence
        itemData {
          ... on Video {
            id
            title
            description
            watchTime
            videoAsset {
              id
              url
              information {
                durationInSeconds
              }
            }
            slug
          }
          ... on Podcast {
            id
            title
            description
            playTime
            audio {
              information {
                durationInSeconds
              }
            }
            slug
          }
        }
      }
    }
  }

  fragment TagCarouselFragment on TagCarouselData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment TagCloudFragment on TagCloudData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment FeaturedTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      tag {
        ...TagFragment
      }
      autoGenerateSortBy
      autoGenerate
    }
  }

  fragment CTABlockFragment on CTABlockData {
    config {
      eventLocation
      eventDate
      eventTime
      asset {
        ...AssetFragment
      }
      primaryAction {
        asset {
          ...AssetFragment
        }
        internalPageType
        title
        url
        type
      }
      secondaryAction {
        asset {
          ...AssetFragment
        }
        internalPageType
        title
        url
        type
      }
      theme
    }
  }

  fragment CTABannerWithTextFragment on CTABannerWithTextData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        title
        url
        type
        internalPageType
      }

      theme
    }
  }
  fragment HeroFragment on HeroData {
    config {
      asset {
        ...AssetFragment
      }
      eventDate
      eventTime
      eventLocation
      alignment
      primaryAction {
        title
        type
        url
        asset {
          ...AssetFragment
        }
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        asset {
          ...AssetFragment
        }
        internalPageType
      }
    }
  }

  fragment CTABannerFragment on CTABannerData {
    config {
      foregroundImage {
        small {
          ...AssetFragment
        }
        medium {
          ...AssetFragment
        }
        large {
          ...AssetFragment
        }
      }
      backgroundImage {
        small {
          ...AssetFragment
        }
        medium {
          ...AssetFragment
        }
        large {
          ...AssetFragment
        }
      }
      assetHeight {
        desktop
        mobile
        tablet
      }
      primaryAction {
        url
        type
        internalPageType
      }
    }
  }

  fragment informationFragment on InformationData {
    config {
      asset {
        url
        blurhash
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        asset {
          ...AssetFragment
        }
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        asset {
          ...AssetFragment
        }
        internalPageType
      }
    }
  }

  fragment faqsFragment on FAQData {
    config {
      questions {
        question
        answer
      }
    }
  }

  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }

  fragment HTMLTextBlockFragment on HTMLTextBlockData {
    config {
      content
    }
  }

  fragment podcastPlayerFragment on PodcastPlayerData {
    config {
      podcast {
        order
        collections {
          id
          slug
          title
        }
        collectionSequence
        id
        title
        slug
        description
        topics {
          id
          name
          primaryColor
          slug
        }
        tags {
          key
          slug
          name
        }
        autoPublishAt
        audio {
          id
          url
          information {
            durationInSeconds
          }
          service
        }
        previewAudio {
          id
          url
          information {
            durationInSeconds
          }
          service
        }
        video {
          id
          title
          description
          categoryKey
          status
          service
          serviceAssetId
          serviceTargetId
          serviceRefData
          serviceTimelineThumbnail
          policy
          url
          languageCode
          contentType
          metaData
          blurhash
          createdAt
          updatedAt
        }
        previewVideo {
          id
          title
          description
          categoryKey
          status
          service
          serviceAssetId
          serviceTargetId
          serviceRefData
          serviceTimelineThumbnail
          policy
          url
          languageCode
          contentType
          metaData
          blurhash
          createdAt
          updatedAt
        }
        image {
          id
          url
          blurhash
        }
        requiredPlan
        isPremium
      }
    }
    settings {
      podcastAsk
      podcastShare
    }
  }

  fragment formDisplayFragment on FormDisplayData {
    config {
      autoFillForm
      form {
        id
        title
        submitCount
        description
        slug
        ctas {
          icon {
            url
            id
          }
          actionUrl
          action
          label
          type
        }
        type
        fields {
          key
          label
          order
          type
          required
          options {
            key
            label
            order
          }
        }
      }
    }
  }

  fragment subscriptionBlockFragment on SubscriptionBlockData {
    settings {
      title
      description
      action
    }
    config {
      image {
        url
        blurhash
      }
    }
  }

  fragment subscriptionHeaderFragment on SubscriptionHeaderData {
    settings {
      title
      action
    }
  }

  fragment formCarouselFragment on FormCarouselData {
    config {
      formLimit
      type
      sortBy
      forms {
        id
        title
        description
        slug
        submitCount
        type
        ctas {
          label
          type
          actionUrl
          action
          icon {
            id
            url
          }
        }
      }
    }
    settings {
      formShare
    }
  }

  fragment subscriptionListFragment on SubscriptionListData {
    config {
      key
      subscriptionPlans {
        ...subscriptionPlanFragment
      }
    }
  }

  fragment articleListFragment on ArticleListData {
    settings {
      title
      description
    }
    config {
      actionName
      actionType
    }
  }

  fragment CTAButtonListFragment on CTAButtonListData {
    settings {
      bottomSeparator
    }
    config {
      items {
        url
        type
        title
        internalPageType
        buttonType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment PageModuleData on PageModuleData {
    ... on VideoContinueWatchingCarouselData {
      ...VideoContinueWatchingCarouselFragment
    }
    ... on VideoCarouselData {
      ...VideoCarouselFragment
    }
    ... on VideoListData {
      ...VideoListFragment
    }
    ... on VideoGridData {
      ...VideoGridFragment
    }
    ... on FeaturedVideoData {
      ...FeaturedVideoFragment
    }
    ... on VideoPlayerData {
      ...VideoPlayerFragment
    }
    ... on FeaturedVideoSliderData {
      ...FeaturedVideoSliderFragment
    }
    ... on PodcastListData {
      ...PodcastListFragment
    }
    ... on CollectionGridData {
      ...CollectionGridFragment
    }
    ... on CollectionPillCarouselData {
      ...CollectionPillCarouselFragment
    }
    ... on CollectionCarouselData {
      ...CollectionCarouselFragment
    }
    ... on CollectionListData {
      ...CollectionListFragment
    }
    ... on FeaturedCollectionData {
      ...FeaturedCollectionFragment
    }
    ... on CollectionDetailData {
      ...CollectionDetailFragment
    }
    ... on FeaturedCollectionSliderData {
      ...FeaturedCollectionSliderFragment
    }
    ... on TopicListData {
      ...TopicListFragment
    }
    ... on TopicGridData {
      ...TopicGridFragment
    }
    ... on TopicCarouselData {
      ...TopicCarouselFragment
    }
    ... on FeaturedTopicData {
      ...FeaturedTopicFragment
    }
    ... on TagCloudData {
      ...TagCloudFragment
    }
    ... on TagCarouselData {
      ...TagCarouselFragment
    }
    ... on FeaturedTagData {
      ...FeaturedTagFragment
    }
    ... on CTABlockData {
      ...CTABlockFragment
    }
    ... on CTABannerWithTextData {
      ...CTABannerWithTextFragment
    }
    ... on HeroData {
      ...HeroFragment
    }
    ... on CTABannerData {
      ...CTABannerFragment
    }
    ... on InformationData {
      ...informationFragment
    }
    ... on FAQData {
      ...faqsFragment
    }
    ... on SpacerData {
      ...spaceFragment
    }
    ... on HTMLTextBlockData {
      ...HTMLTextBlockFragment
    }
    ... on PodcastPlayerData {
      ...podcastPlayerFragment
    }
    ... on FormDisplayData {
      ...formDisplayFragment
    }
    ... on FormCarouselData {
      ...formCarouselFragment
    }
    ... on SubscriptionBlockData {
      ...subscriptionBlockFragment
    }
    ... on SubscriptionHeaderData {
      ...subscriptionHeaderFragment
    }
    ... on SubscriptionListData {
      ...subscriptionListFragment
    }
    ... on ArticleListData {
      ...articleListFragment
    }
    ... on CTAButtonListData {
      ...CTAButtonListFragment
    }
  }
`;

export const GET_PAGE = gql`
  ${PAGE_MODULE_DATA_FRAGMENT}
  query page($where: PageWhereInput!) {
    page(where: $where) {
      id
      slug
      title
      description
      status
      type
      metaHeader
      metaFooter
      modules {
        id
        pageId
        order
        status
        title
        description
        type
        moduleData {
          ...PageModuleData
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PAGE_ADMIN = gql`
  ${PAGE_MODULE_DATA_FRAGMENT}
  query pageAdmin($where: PageUniqueInput!) {
    page: pageAdmin(where: $where, data: { applySettings: true }) {
      id
      slug
      title
      description
      status
      type
      metaHeader
      metaFooter
      modules {
        id
        pageId
        order
        status
        title
        description
        type
        moduleData {
          ...PageModuleData
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PAGE_V2 = gql`
  ${PAGE_MODULE_DATA_FRAGMENT}
  query pageV2($where: PageWhereInput!, $filter: PageFilterInput) {
    pageV2(where: $where, filter: $filter) {
      id
      slug
      title
      description
      status
      type
      metaHeader
      metaFooter
      modules {
        id
        pageId
        order
        status
        title
        description
        type
        showSkeleton
        moduleData {
          ...PageModuleData
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MENUS = gql`
  query menus {
    menus {
      count
      menus {
        id
        name
        icon {
          url
        }
        url
        position
        type
        openInSameTab
      }
    }
  }
`;

export const GET_TABLES = gql`
  query tables($filter: TablesFilter, $sort: TablesSort) {
    myTables(filter: $filter, sort: $sort) {
      tables {
        id
        slug
        name
        status
        locationType
        location
        startDate
        endDate
        days
        timezone
        videoCount
        rsvpCount
        isDisabled
        isOwner
        createdBy {
          id
        }
      }
      count
    }
  }
`;

export const GET_TOPICS = gql`
  query topics($filter: TopicsFilter) {
    topics(filter: $filter) {
      count
      topics {
        id
        name
      }
    }
  }
`;

export const GET_VIDEOS = gql`
  query videos($filter: VideosFilter, $sort: VideosSort) {
    videos(filter: $filter, sort: $sort) {
      count
      videos {
        id
        slug
        title
        description
        video: videoAsset {
          id
          url
          information {
            durationInSeconds
          }
        }
        videoThumbnail {
          id
          url
          blurhash
        }
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_RECENTLY_WATCHED_VIDEOS = gql`
  query recentlyWatchedVideos($filter: WatchVideosFilter) {
    recentlyWatchedVideos(filter: $filter) {
      count
      videos {
        id
        slug
        title
        description
        video: videoAsset {
          id
          url
          information {
            durationInSeconds
          }
        }
        videoThumbnail {
          id
          url
          blurhash
        }
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_TABLE_VIDEOS = gql`
  query tableVideos($filter: TableVideosFilter, $where: TableWhereInput!) {
    tableVideos(filter: $filter, where: $where) {
      count
      videos {
        id
        title
        slug
        description
        topics {
          id
          name
          primaryColor
        }
        tags {
          key
        }
        autoPublishAt
        video: videoAsset {
          id
          url
          information {
            durationInSeconds
          }
        }
        videoThumbnail {
          id
          url
          blurhash
        }
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_TABLE_USER_REQUESTS = gql`
  query tableUserRequests(
    $filter: TableUserRequestsFilter
    $where: TableWhereInput!
  ) {
    tableUserRequests(filter: $filter, where: $where) {
      count
      requests {
        id
        rsvp
        email
        createdAt
        updatedAt
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_ALL_TOPICS = gql`
  query topics($filter: TopicsFilter, $sort: TopicsSort) {
    topics(filter: $filter, sort: $sort) {
      count
      topics {
        id
        key
        name
        primaryColor
        slug
        createdBy {
          id
          email
          authService
          authServiceId
          firstName
          lastName
          profileImage
          metaData
          contactNumber
          countryCode
          createdAt
          updatedAt
        }
        metaHeader
        metaFooter
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_TAGS = gql`
  query tags($filter: TagsFilter, $sort: TagsSort) {
    tags(filter: $filter, sort: $sort) {
      count
      tags {
        key
        name
        primaryColor
        slug
        createdBy {
          id
          email
          authService
          authServiceId
          firstName
          lastName
          profileImage
          metaData
          contactNumber
          countryCode
          createdAt
          updatedAt
        }
        metaHeader
        metaFooter
      }
    }
  }
`;

export const VIDEO_FRAGMENT = gql`
  fragment VideoFragment on Video {
    id
    title
    slug
    description
    topics {
      id
      name
      primaryColor
      slug
    }
    tags {
      key
      slug
      name
    }
    autoPublishAt
    video: videoAsset {
      id
      url
      information {
        durationInSeconds
      }
    }
    videoThumbnail {
      id
      url
      blurhash
    }
    imageThumbnail {
      id
      url
      blurhash
    }
    isPremium
    requiredPlan
    previewVideo {
      id
      url
      title
      blurhash
    }
  }
`;

export const PODCAST_FRAGMENT = gql`
  fragment PodcastFragment on Podcast {
    id
    title
    slug
    description
    topics {
      id
      name
      primaryColor
      slug
    }
    tags {
      key
      slug
      name
    }
    autoPublishAt
    audio {
      id
      url
      information {
        durationInSeconds
      }
    }
    imageThumbnail {
      id
      url
      blurhash
    }
    requiredPlan
    allowedPlans {
      key
    }
  }
`;

export const GET_ALL_VIDEOS = gql`
  ${VIDEO_FRAGMENT}
  query videos($filter: VideosFilter, $sort: VideosSort) {
    videos(filter: $filter, sort: $sort) {
      count
      videos {
        ...VideoFragment
      }
    }
  }
`;

export const GET_CONTINUE_WATCHING_VIDEOS = gql`
  ${VIDEO_FRAGMENT}
  query recentlyWatchedVideos($filter: WatchVideosFilter) {
    videos: recentlyWatchedVideos(filter: $filter) {
      count
      videos {
        ...VideoFragment
        watchTime
      }
    }
  }
`;

export const GET_ALL_COLLECTIONS = gql`
  query collections($filter: CollectionsFilter, $sort: CollectionsSort) {
    collections(filter: $filter, sort: $sort) {
      count
      collections {
        id
        title
        slug
        status
        itemsCount
        thumbnail {
          id
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  ${CURRENT_SUBSCRIPTION_PLAN_FRAGMENT}
  ${USER_FRAGMENT}
  query currentUser {
    currentUser {
      ...UserFragment
    }
    currentSubscriptionPlan {
      ...CurrentSubscriptionPlanFragment
    }
  }
`;

export const GET_SEARCH_VIDEOS = gql`
  query searchVideos($filter: VideosFilter, $sort: VideosSort) {
    searchVideos(filter: $filter, sort: $sort) {
      count
      videos {
        id
        title
        slug
        isPremium
        description
        topics {
          id
          name
          primaryColor
          slug
        }
        tags {
          key
          slug
          name
        }
        autoPublishAt
        video: videoAsset {
          id
          url
          information {
            durationInSeconds
          }
        }
        videoThumbnail {
          id
          url
          blurhash
        }
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_ASSET = gql`
  query asset($where: AssetWhereUniqueInput!) {
    asset(where: $where) {
      id
      title
      url
      blurhash
    }
  }
`;

export const GET_VIDEO_WATCH_TIME = gql`
  query videosProgress($videoIds: [ID!]!) {
    videosProgress(videoIds: $videoIds) {
      videoId
      watchTime
    }
  }
`;

export const GET_PODCAST_WATCH_TIME = gql`
  query PodcastsProgress($podcastIds: [ID!]!) {
    podcastsProgress(podcastIds: $podcastIds) {
      playTime
      podcastId
    }
  }
`;

export const GET_VIDEO = gql`
  ${VIDEO_FRAGMENT}
  query video($where: VideoWhereUniqueInput!) {
    video(where: $where) {
      ...VideoFragment
    }
  }
`;

export const GET_PLAYLISTS_RECENTLY_WATCHED_VIDEOS = gql`
  query recentlyWatchedVideos($filter: WatchVideosFilter) {
    recentlyWatchedVideos(filter: $filter) {
      count
      videos {
        id
        slug
        title
        description
        video: videoAsset {
          id
          url
          information {
            durationInSeconds
          }
        }
        videoThumbnail {
          id
          url
          blurhash
        }
        imageThumbnail {
          id
          url
          blurhash
        }
        watchTime
      }
    }
  }
`;

export const GET_PLAYLISTS = gql`
  query playlists($filter: PlaylistsFilter, $data: PlaylistsDataInput) {
    playlists(filter: $filter, data: $data) {
      count
      playlists {
        id
        name
        slug
        itemsCount
        isItemSaved
        thumbnail {
          blurhash
          url
        }
      }
    }
  }
`;

export const GET_PLAYLIST_DETAILS = gql`
  query playlist($slug: String!) {
    playlist(where: { slug: $slug }) {
      id
      name
      slug
    }
  }
`;

export const PLAYLIST_VIDEOS = gql`
  query playlistVideos($slug: String!, $filter: PlaylistVideosFilter) {
    playlistVideos(where: { slug: $slug }, filter: $filter) {
      count
      videos {
        id
        order
        slug
        title
        description
        imageThumbnail {
          url
          blurhash
        }
        videoThumbnail {
          url
          blurhash
        }
        createdAt
      }
    }
  }
`;

export const SEARCH_HISTORIES = gql`
  query searchHistories($filters: SearchHistoriesFilter!) {
    searchHistories(filters: $filters) {
      count
      searchHistories {
        id
        text
      }
    }
  }
`;

export const PLAYLIST_NEXT_VIDEO = gql`
  query playlistNextVideo($order: Int!, $slug: String!) {
    playlistNextVideo(where: { slug: $slug }, data: { currentOrder: $order }) {
      slug
      order
    }
  }
`;

export const GET_ALL_PODCASTS = gql`
  ${PODCAST_FRAGMENT}
  query podcasts($filter: PodcastsFilter, $sort: PodcastsSort) {
    podcasts(filter: $filter, sort: $sort) {
      count
      podcasts {
        ...PodcastFragment
      }
    }
  }
`;

export const RESEND_VERIFY_USER = gql`
  mutation resendVerifyUser($data: ResendVerifyUserInput!) {
    resendVerifyUser(data: $data) {
      message
    }
  }
`;

export const SUBSCRIPTION_PLANS = gql`
  ${SUBSCRIPTION_PLAN_FRAGMENT}
  query SubscriptionPlans(
    $filter: SubscriptionPlansFilter
    $sort: SubscriptionPlansSort
  ) {
    subscriptionPlans(filter: $filter, sort: $sort) {
      count
      subscriptionPlans {
        ...subscriptionPlanFragment
      }
    }
  }
`;

export const PAYMENT_HISTORY = gql`
  query PaymentHistory($filter: UserPaymentHistoryFilter) {
    userPaymentHistory(filter: $filter) {
      count
      paymentHistory {
        amount
        invoiceUrl
        planKey
        status
        createdAt
      }
    }
  }
`;

export const CURRENT_SUBSCRIPTION_PLAN = gql`
  ${CURRENT_SUBSCRIPTION_PLAN_FRAGMENT}
  query CurrentSubscriptionPlan {
    currentSubscriptionPlan {
      ...CurrentSubscriptionPlanFragment
    }
  }
`;

export const SUBSCRIPTION_PLAN = gql`
  query subscriptionPlan($where: SubscriptionPlanWhereInput!) {
    subscriptionPlan(where: $where) {
      amount {
        usd {
          price
        }
      }
      id
      name
      type
      frequency
      allowCustomAmount
      badges {
        createdAt
        id
        image {
          id
          blurhash
          url
        }
        isActive
        isSelected
        key
        name
        workspaceId
      }
      images {
        id
        blurhash
        url
      }
    }
  }
`;

export const GET_FORM_DATA = gql`
  query FormSubmission($where: FormWhereUniqueInput!) {
    formSubmission(where: $where) {
      createdAt
      formId
      id
      submission
    }
  }
`;

export const PUBLISHED_PAGES = gql`
  query PublishedPages($where: PublishedPagesWhereInput!) {
    publishedPages(where: $where) {
      slugs
    }
  }
`;

export const GET_IS_EXISTING_USER = gql`
  query EmailExist($data: EmailExistInput) {
    emailExist(data: $data) {
      isEmailExist
    }
  }
`;

export const PREVIEW_SUBSCRIPTION = gql`
  query PreviewSubscriptionChange($data: PreviewSubscriptionChangeInput!) {
    previewSubscriptionChange(data: $data) {
      amountDue
      dueDate
      effectiveDate
      paymentMethod {
        brand
        lastDigit
        type
      }
      taxDetails {
        tax
        totalAmount
        totalExcludingTax
      }
    }
  }
`;

export const SUBSCRIPTION_LEADER_BOARD = gql`
  query SubscriptionLeaderBoard {
    subscriptionLeaderBoard {
      firstName
      lastName
      price
      id: userId
      city
      country
      state
    }
  }
`;

export const CUSTOM_AMOUNT = gql`
  query SubscriptionPlans {
    subscriptionPlans {
      subscriptionPlans {
        allowCustomAmount
        amount {
          usd {
            price
            maxPrice
          }
        }
        frequency
        id
        key
        name
      }
    }
    subscriptionLeaderBoard(filter: { limit: 10 }) {
      firstName
      lastName
      price
      id: userId
      city
      country
      state
    }
  }
`;

export const SUBSCRIPTION_PLANS_SLUGS = gql`
  query SubscriptionPlans {
    subscriptionPlans {
      count
      subscriptionPlans {
        id
        key
      }
    }
  }
`;

export const GET_SUBSCRIPTION_PLAN_META_INFO = gql`
  query SubscriptionPlan($where: SubscriptionPlanWhereInput!) {
    subscriptionPlan(where: $where) {
      id
      name
      description
    }
  }
`;

export const RATE_LIMIT_CHECKER = gql`
  query rateLimitChecker {
    rateLimitChecker
  }
`;
