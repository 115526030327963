import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    authService
    authServiceId
    firstName
    lastName
    profileImage
    permissions
    roles
    isActive
    metaData
    countryCode
    contactNumber
    isEmailVerified
    zipCode
    username
    assignedPlan
    effectivePlan
    currentPlan
    address {
      addressLine1
      addressLine2
      city
      country
      state
      fullName
      type
      zipCode
    }
    uid
    badgeId
  }
`;

export const CURRENT_SUBSCRIPTION_PLAN_FRAGMENT = gql`
  fragment CurrentSubscriptionPlanFragment on CurrentSubscriptionPlan {
    id
    type
    frequency
    planId
    planKey
    planName
    status
    startedAt
    expiredAt
    amount
    leaderBoardRank
    isSubscriptionDowngraded
    upcomingPlanDetails {
      key
      frequency
      name
      amount
    }
    paymentMethod {
      brand
      lastDigit
      type
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      accessToken
      user {
        effectivePlan
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($data: LoginInput!) {
    login(data: $data) {
      accessToken
      refreshToken
      user {
        effectivePlan
      }
    }
  }
`;

export const LOGIN_WITH_TOKEN = gql`
  mutation loginWithToken($data: LoginWithTokenInput) {
    loginWithToken(data: $data) {
      accessToken
      refreshToken
      user {
        effectivePlan
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($data: UserSignUpInput!) {
    signUp(data: $data) {
      accessToken
      refreshToken
      user {
        effectivePlan
      }
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($data: VerifyUserInput!) {
    verifyUser(data: $data) {
      accessToken
      refreshToken
      resetToken
      user {
        effectivePlan
      }
    }
  }
`;

export const VERIFY_RESET_TOKEN = gql`
  mutation verifyResetToken($data: ResetTokenInput!) {
    verifyResetToken(data: $data) {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      message
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout {
      message
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  ${USER_FRAGMENT}
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
      user {
        ...UserFragment
      }
    }
  }
`;

export const SUBMIT_CONTACT_INQUIRY = gql`
  mutation submitContactInquiry($data: SubmitContactInquiryInput!) {
    submitContactInquiry(data: $data) {
      message
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation CreateQuestion($data: CreateQuestionInput!) {
    createQuestion(data: $data) {
      message
      question {
        id
        question
        isHidden
        createdBy {
          id
          email
          uid
          authService
          authServiceId
          firstName
          lastName
          username
          profileImage
          permissions
          roles
          isActive
          metaData
          contactNumber
          countryCode
          zipCode
          address {
            addressLine1
            addressLine2
            state
            city
            country
          }
          createdAt
          updatedAt
          isEmailVerified
          isContactNumberVerified
          currentPlan
          assignedPlan
          effectivePlan
          assignedPlanExpiry
          oneTimePlans {
            key
            isActive
            createdAt
            expiredAt
          }
        }
        createdAt
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      message
    }
  }
`;

export const GET_PROFILE_IMAGE_SIGNED_URL = gql`
  query getProfileImageUploadSignedUrl(
    $data: GetProfileImageUploadSignedUrlInput!
  ) {
    getProfileImageUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const UPDATE_VIDEO_WATCH_TIME = gql`
  mutation upsertVideoWatchTime($data: UpsertVideoWatchTimeInput!) {
    upsertVideoWatchTime(data: $data) {
      message
    }
  }
`;

export const UPDATE_PODCAST_PLAY_TIME = gql`
  mutation UpsertPodcastPlayTime($data: UpsertPodcastPlayTimeInput!) {
    upsertPodcastPlayTime(data: $data) {
      message
    }
  }
`;

export const MIGRATE_LEGACY_ACCOUNT = gql`
  mutation migrateLegacyAccount($data: MigrateLegacyAccountInput!) {
    migrateLegacyAccount(data: $data) {
      message
    }
  }
`;

export const CREATE_PLAYLIST = gql`
  mutation createPlaylist($data: CreatePlaylistInput!) {
    createPlaylist(data: $data) {
      message
      playlist {
        slug
      }
    }
  }
`;

export const DELETE_PLAYLIST = gql`
  mutation deletePlaylist($slug: String!) {
    deletePlaylist(where: { slug: $slug }) {
      message
    }
  }
`;

export const UPDATE_PLAYLIST = gql`
  mutation updatePlaylist($slug: String!, $data: UpdatePlaylistInput!) {
    updatePlaylist(where: { slug: $slug }, data: $data) {
      message
      playlist {
        name
        slug
      }
    }
  }
`;

export const REMOVE_PLAYLIST_VIDEO = gql`
  mutation addRemovePlaylistVideo($slug: String!, $videoId: ID!) {
    addRemovePlaylistVideo(
      where: { slug: $slug }
      data: { videoId: $videoId, action: REMOVE }
    ) {
      message
    }
  }
`;

export const CREATE_SEARCH_HISTORY = gql`
  mutation createSearchHistory($data: CreateSearchHistoryData!) {
    createSearchHistory(data: $data) {
      message
    }
  }
`;

export const SEND_OTP = gql`
  mutation resendOtp($data: ResendOtpInput!) {
    resendOtp(data: $data) {
      message
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyUserContactNo($data: VerifyUserContactNoInput!) {
    verifyUserContactNo(data: $data) {
      message
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount {
    deleteAccount {
      message
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($where: CreateUpdateSubscriptionInput!) {
    createSubscription(where: $where) {
      message
      paymentData {
        id
        key
      }
      subscriptionPlan {
        amount {
          usd {
            maxPrice
            price
          }
        }
      }
      taxDetails {
        tax
        totalAmount
        totalExcludingTax
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($data: CreateUpdateSubscriptionInput!) {
    updateSubscription(data: $data) {
      message
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      message
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdateSubscriptionPaymentMethod(
    $data: UpdateSubscriptionPaymentMethodInput!
  ) {
    updateSubscriptionPaymentMethod(data: $data) {
      message
    }
  }
`;

export const ADD_REMOVE_PLAYLIST_ITEM = gql`
  mutation AddRemovePlaylistItem(
    $where: PlaylistWhereUniqueInput!
    $data: AddRemovePlaylistItemDataInput!
  ) {
    addRemovePlaylistItem(where: $where, data: $data) {
      message
    }
  }
`;

export const SUBMIT_FORM = gql`
  mutation SubmitForm($where: FormWhereUniqueInput!, $data: SubmitFormInput!) {
    submitForm(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_ACCOUNT_OTP = gql`
  mutation SendDeleteAccountOtp {
    sendDeleteAccountOtp {
      message
    }
  }
`;

export const VERIFY_DELETE_ACCOUNT_OTP = gql`
  mutation VerifyDeleteAccountOtp($data: VerifyDeleteAccountOtpInput!) {
    verifyDeleteAccountOtp(data: $data) {
      message
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpsertUserAddress($data: UpsertUserAddressInput!) {
    upsertUserAddress(data: $data) {
      address {
        addressLine1
        addressLine2
        state
        city
        country
        zipCode
        type
      }
      message
    }
  }
`;
